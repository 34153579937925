.feedContainer {
    margin-top: 24px;
    max-width: 880px;
    margin: 0 auto;
    padding: 24px 8px;

    h1, h2, p {
        margin: 0px;
    }

    hr {
        background-color: grey;
        border: none;
        height: 2px;
        margin: 0px 4px;
    }    
    
    .feedTitle {
        font-weight: bold;
    }

    .projectContainer {
        min-width:  280px;
        position: relative;
        margin: 16px auto;
        padding: 16px;
        text-align: left;
        border-radius: 4px;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,.12);
        background: rgba(44,14,55,.1);
        .projectDescAndImg {
            display: flex;
            flex-direction: row-reverse;
            .projectDescription {
                padding: 16px;
                .projectHeader, .projectSubheader {
                    margin: 4px;
                }
                .projectHeader {
                    font-size: 28px;
                }
                .projectSubheader {
                    font-style: italic;
                    margin-top: 12px;
                }
            }
            .projectPicture {
                min-width: 280px;
                max-width: 60%;
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
        .projectLink {
            position: absolute;
            right: 16px;
            bottom: 16px;
            border-radius: 4px;
            outline: none;
            border: 1px solid grey;
            height: 28px;
            background: white;
            margin-left: 0px;
            cursor: pointer;
        }
        .tags {
            margin: 8px 0px 16px 0px;
            .tag {
                margin: 0px 4px;
                padding: 2px 4px;
                background-color: white;
            }
        }

    }
    .tagToggles {
        margin-top: 16px;
        .tagToggle {
            padding: 2px 4px;
            margin: 0 4px;
            background: white;
            border: 1px solid grey;
            border-radius: 4px;
        }
    }
}

@media (max-width:720px) {
    .feedContainer {
        .projectContainer {
            text-align:center;
            .projectDescAndImg {
                flex-direction: column;
                .projectHeader {
                    font-size: 24px !important;
                }    
                .projectSubheader {
                    font-size: 14px;
                }
                .projectPicture {
                    width: 100%;
                    text-align: center;
                    max-width:unset;
                    img {
                        max-width: 500px;
                    }
                }
            } 
            .projectLink {
                position: relative;
                right: unset;
                bottom: -4px;
                margin: 0 auto;
            }
        }
    }
}