
.totalAvg {
    stroke: rgba(128,128,128,.4);
}
.teamAvg {
    stroke: rgba(255,0,0,.2);
}
.guideline {
    stroke: rgba(0,0,0,.2);
    stroke-dasharray: 3px;
}
.guidelineLabel {
    font-size: 10px;
    text-anchor: middle;
    font-style: italic;
}


.mlbPitchingContainer {
    margin: 16px auto;
    max-width: 1080px;
    min-width: 300px;

    .controls {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        top: 50px;
        position: relative;
        span {
            margin-right: 4px;
        }
    }
    .header, .controls {
        padding: 0px 16px;
    }
    .title {
        font-size: 32px;
    }
    .subtitle {
        font-size: 14px;
    }
    #eachTeam {
        position: relative;
    }
    #legend {
        height: 80px;
        width: 100%;
        .teamAvgText, .leagueAvgText {
            font-size: 12px;
            font-style: italic;
        }
        .leagueAvgText {
            text-anchor: middle;
        }
        .teamAvgText {
            text-anchor: start;
        }
    }
    .teamRow {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        position: relative;
        min-height: 180px;

        .teamOverview, .teachChartContainer {
            padding: 16px;
            text-align: right;
        }
        .teamOverview {
            text-align: right;
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 8px 20px;
            padding: 4px 8px;
            z-index: 1;
            background: rgb(230, 230, 230);
            border: 1px solid white;
            .teamName {
                margin: 0px;
                font-size: 20px;
            }
            .teamStats {
                margin: 2px;
                font-size: 12px;
                font-style: italic;
            }
        }
        .teamChartContainer {
            flex-grow: 1;
            position: relative;
            .teamPlayersChart {
                background-color: rgba(0,0,0,.05);
                box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);


                .avgLine {
                    stroke: grey;
                }
                .teamAvgLine {
                    stroke: red;
                }
                .playerInitials {
                    text-anchor: middle;
                    font-size: 9px;
                    pointer-events: none;
                    font-weight: bold;
                }
                circle {
                    stroke-width: 0px;
                    &:hover {
                        stroke-width: 2px;
                    }
                }
            }
            .tooltipContainer {
                z-index:1;
                box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
                position: absolute;
                left: 0px;
                bottom: 0px;
                border: 1px solid rgba(0,0,0,.2);
                background: white;
                border-radius: 4px;
                padding: 8px;
                display: block;
                pointer-events: none;

                margin: 0;
                text-align: left;

                width: 180px;

                .playerName, .playerStats {
                    margin: 0;
                }
                .playerName {
                    font-size: 16px;
                }
                .playerStats {
                    font-size: 12px;
                }
                &.hidden {
                    display: none;
                }
            }
        }
    }    
}



@media (max-width:720px) {
    .guidelineLabel {
        font-size: 8px;
    }
    
    .controls * {
        font-size: 12px;
    }

    .guidelineLabel50, .guideline50, .guidelineLabel150, .guideline150  {
        display: none;
    }

    .mlbPitchingContainer {
        .title {
            font-size: 20px;
        }
        .subtitle {
            font-size: 10px;
        }
        .teamRow {    
            .teamOverview {
                .teamName {
                    font-size: 16px;
                }
                .teamStats {
                    font-size: 10px;
                }
            }
            .teamChartContainer {
                .tooltipContainer {
                    width: 136px; 
                    .playerName {
                        font-size: 12px; 
                    }
                    .playerStats {
                        font-size: 9px; 
                    }
                }
            }
        }    
    }
}

